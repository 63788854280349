<template>
  <div class="terms">
    <div lang="en" class="wrapper">
      <div id="terms-header">
        <h2 class="terms-title">Terms of Service</h2>
        <p class="terms-description">
          We’d like to extend our gratitude for taking the time to look through
          our games (known henceforth as “our Services”) and browse our content.
          Supergames, Inc. (known henceforth as “the Company”) provide the services
          of the application. These are our Terms of Service (called “Terms”).
          Having made use of our Services means that you consent to the “Terms
          of Service” presented here. If you do not agree with any of the terms
          or are not able to do so, it would be advisable to discontinue your
          use of our Services.
        </p>
      </div>
      <div>
        <div class="terms-body-item">
          <h3>Article I (Purpose)</h3>
          <p class="terms-description">
            The “Terms of Service” enumerate not only the rights and obligations
            of the users, but also any liabilities. Listed as well are the
            methods and conditions that are required for the use of the
            Company’s Services.
          </p>
        </div>
        <div class="terms-body-item">
          <h3>Article II (Notification and revision of “Terms of Service”)</h3>
          <p class="terms-description">
            The Company may make the necessary changes to the Terms should any
            need arise for the need of such, provided that the changes are
            permissible under the law and other pertinent rulings. Once changes
            have been made to the Terms, the users shall be notified of these
            revisions by the Company the next time the user uses our Services.
          </p>
          <p class="terms-description">
            Should the users still make use of our Services, this is to entail
            that the users have understood the changes that have been made to
            the Terms and that they are agreed upon.
          </p>
        </div>
        <div class="terms-body-item">
          <h3>
            Article III (Applicable Provisions Other Than the “Terms of
            Service”)
          </h3>
          <p class="terms-description">
            What was not clearly defined in the Terms shall be classified under
            the Framework Act on Telecommunications, the Telecommunications
            Business Act, and other relevant rules and regulations.
          </p>
        </div>
        <div class="terms-body-item">
          <h3>Article IV (Service Hours)</h3>
          <p class="terms-description">
            Barring any unforeseen technical difficulties and scheduled
            maintenance, our Services shall continue to run and will remain
            available for the duration of the year, within full (24 hour) days.
            The Company will have the right to postpone any and all of its
            services provided that the circumstances would prevent them from
            providing the users with the usual standard. Such circumstances may
            come in the form of, but not limited to, emergencies of the national
            level, errors that have to do with electrical failure, and a large
            amount of site-wide usage. The Company is likewise allowed to
            allocate at assign specific service hours for each particular
            service that they provide. The schedule of these services, and any
            changes that may occur, shall be made aware of by the users.
          </p>
        </div>
        <div class="terms-body-item">
          <h3>Article V (Obligations of the Users)</h3>
          <p class="terms-description">
            The users are forbidden to participate in or partake of any actions
            mentioned below:
          </p>
          <li class="terms-description indent-one">
            Making use of the contents and services provided by the Company for
            anything except the user’s own private use, other than having the
            Company’s consent. Similarly, taking material from our Services and
            publishing them with any medium and handing them over to a third
            party;
          </li>
          <li class="terms-description indent-one">
            Giving out information that would go against trademarks, patents,
            copyright, and other intellectual property to a third party via
            e-mail and other venues of communication;
          </li>
          <li class="terms-description indent-one">
            Giving out information of the obscene variety in the form of words,
            images, and the like that may be harmful via e-mail and other venues
            of communication;
          </li>
          <li class="terms-description indent-one">
            Taking part of an act that would constitute as illegal or a
            criminal;
          </li>
          <li class="terms-description indent-one">
            Any action that would go against the law.
          </li>
          <p class="terms-description">
            The users of our Services shall consent to what is written by the
            Company in the Terms and follow the guidelines set by the Company.
          </p>
          <p class="terms-description">
            Using our Services for business and similar reasons, unless approved
            before by the Company, is not allowed. The Company is not liable
            should any ill befall the business venture. Any loss on the
            Company’s part should be shouldered by the user.
          </p>
          <p class="terms-description">
            The users are forbidden from using our Services to manage business
            activities including, but not limited to: hacking, publishing
            advertisements for the sole use of making money, peddling
            pornography and other disturbing phenomena. Any of these actions
            shall be felt with by the relevant authorities and legal action
            could be taken against the user.
          </p>
        </div>
        <div class="terms-body-item">
          <h3>Article VI (Transactions with Advertisers)</h3>
          <p class="terms-description">
            Any losses or damages that may have occurred due to another user’s
            taking part in promotional activities of an advertiser in which the
            services of the Company were used will not be made liable by the
            Company.
          </p>
        </div>
        <div class="terms-body-item">
          <h3>Article VII (Restriction of Use)</h3>
          <p class="terms-description">
            If a user were to participate in any of the actions listed below,
            the Company may choose to bar the user from using the Company’s
            services. This may occur without prior notice to the user for any
            period of time:
          </p>
          <li class="terms-description indent-one">
            If a user has prevented the services of the Company from being
            delivered in a suitable manner;
          </li>
          <li class="terms-description indent-one">
            If a user willingly and knowingly spread any harmful and offensive
            content that goes against morals and custom;
          </li>
          <li class="terms-description indent-one">
            If a user, through the services provided by the Company, willingly
            makes use of those services against national interest and the
            well-being of society;
          </li>
          <li class="terms-description indent-one">
            If a user has used our Services to intentionally harm another
            person, or is harmful in general;
          </li>
          <li class="terms-description indent-one">
            If a user disrupts the stability and integrity of the services
            provided by the Company by inputting massive amounts of data or
            advertising;
          </li>
          <li class="terms-description indent-one">
            If a user makes use of our Services to spread a malicious software
            or virus that could not only cause a loss of data, but also
            compromise the integrity of the equipment and facilities;
          </li>
          <li class="terms-description indent-one">
            If a user has stolen any intellectual property that rightfully
            belongs to the Company, a fellow user, or any third party;
          </li>
          <li class="terms-description indent-one">
            If a user, without prior knowledge or consent from the Company.
            duplicates and spreads any information they may have gathered while
            the Company’s services, and does so for commercial use.
          </li>
          <li class="terms-description indent-one">
            If a user goes against the terms of use put together by the Company,
            or other relevant laws.
          </li>
        </div>
        <div class="terms-body-item">
          <h3>Article VIII (Content Rights)</h3>
          <p class="terms-description">
            The term “Content” shall henceforth refer to any textual writing,
            graphics, images and pictures, audio and music, software, videos,
            and any work that has been done by the Company that have been
            published, generated and such by them.
          </p>
          <p class="terms-description">
            <i>Content Ownership, Responsibility and Removal.</i> The Company
            and its licensors own the exclusive rights, interests, and titles
            in, and to the Services of the Content. Falling under that category
            would be intellectual property rights. The services and content of
            the of the Company are protected by laws that are relevant to
            copyright, trademark, and the like. This falls under the
            jurisdiction of all countries. The user must not take away, change,
            or hide any content that denotes the ownership of the Company.
          </p>
        </div>
        <div class="terms-body-item">
          <h3>Article IX (Compensation for Damage)</h3>
          <p class="terms-description">
            For any damages that may have occurred by a user regarding their
            partaking of the services of the Company, which is free of charge,
            the company shall not be to blame. That is, of course, unless it is
            the Company that has done so with intentional misconduct and gross
            negligence.
          </p>
        </div>
        <div class="terms-body-item">
          <h3>Article X (Exemption Clause)</h3>
          <li class="terms-description indent-one">
            The Company shall be excused from the responsibility of having to
            produce its services should it be stopped due to a force of nature
            or an act of God.
          </li>
          <li class="terms-description indent-one">
            The Company shall not be blamed for any technical difficulties
            attributing the difficulty of usage of the services if the user is
            at fault.
          </li>
          <li class="terms-description indent-one">
            The Company shall not be blamed for any loss of profit that a user
            had positive expectations for, or any damages that may have come
            from it.
          </li>
          <li class="terms-description indent-one">
            The veracity and quality of the information and content that have
            been published by the users is not something the Company is liable
            for.
          </li>
          <li class="terms-description indent-one">
            The Company is not liable for any harm caused by a user up to the
            point where it can already be considered a willful misconduct or
            negligence on the user’s part.
          </li>
          <li class="terms-description indent-one">
            The Company is not to be blamed for any User’s penal responsibility
            that comes from information published on our Services that infringes
            the copyright or program copyrights of other persons.
          </li>
        </div>
        <div class="terms-body-item">
          <h3>Article XI (Jurisdiction and Governing Law)</h3>
          <p class="terms-description">
            The Terms of Service will be ruled and interpreted according to the
            laws of the Republic of Korea. Should there be conflict between the
            Company and a user which would result in a lawsuit, the court
            capable will be the court that pursues the word of law. That is, if
            the conflict is between a user whose address is outside of Korea and
            the Company, will defer to the jurisdiction and judgment of Seoul
            Central District Court of Korea.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  metaInfo: {
    title: "Terms of Service – Supergames"
  }
};
</script>

<style scoped>
.terms {
  padding: 60px;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 12px 0;
}

hr {
  margin: 1.5em 0;
}

a {
  color: #007bff;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

@media only screen and (max-width: 650px) {
  .terms {
    padding: 60px 15px 15px;
  }
}
</style>
