<template>
  <header
    :class="{ elevated: top > 0, games: view.games, contact: view.contact }"
  >
    <nav>
      <a @click="go('about')" :class="{ active: view.about }" href="#about"
        >About</a
      >
      <a @click="go('games')" :class="{ active: view.games }" href="#games"
        >Games</a
      >
      <a
        @click="go('contact')"
        :class="{ active: view.contact }"
        href="#contact"
        >Contact</a
      >
    </nav>
  </header>
</template>

<script>
export default {
  name: "topbar",
  data() {
    return {
      top: 0,
      view: {
        about: !1,
        games: !1,
        contact: !1
      }
    };
  },
  created() {
    window.addEventListener("scroll", this.handleScroll);
  },
  unmounted() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  mounted() {
    if (this.$route.name === "Home") this.view.about = !0;

    document.querySelectorAll('a[href^="#"]').forEach(anchor => {
      anchor.addEventListener("click", function(e) {
        e.preventDefault();

        document.querySelector(this.getAttribute("href")).scrollIntoView({
          behavior: "smooth"
        });
      });
    });

    this.handleSections();
  },
  watch: {
    $route({ name }) {
      if (name !== "Home")
        (this.view.about = !1),
          (this.view.games = !1),
          (this.view.contact = !1);
    }
  },
  methods: {
    home() {
      if (this.$route.name === "Home")
        window.scrollTo({ top: 0, behavior: "smooth" });
      else this.$router.push("/");
    },
    go(e) {
      if (this.$route.name !== "Home") {
        this.$router.push("/").then(() => {
          this.view[e] = !0;
          document.querySelector(`#${e}`).scrollIntoView({
            behavior: "smooth"
          });
        });
      } else {
        document.querySelector(`#${e}`).scrollIntoView({
          behavior: "smooth"
        });
      }
    },
    handleScroll() {
      (this.top = window.scrollY), this.handleSections();
    },
    handleSections() {
      const sections = document.querySelectorAll("section");

      sections.forEach(t => {
        this.view[t.id] =
          t.offsetTop - 60 === this.top ||
          (t.offsetTop - 60 < this.top &&
            t.offsetTop - 60 + t.offsetHeight > this.top);
      });
    }
  }
};
</script>

<style scoped>
header {
  transition: background 0.2s, color 0.2s, border 0.2s, box-shadow 0.2s;

  align-items: center;
  background-color: #fff;
  color: #000;
  border-bottom: 1px solid transparent;
  display: flex;
  flex-flow: row nowrap;
  left: 0;
  height: 60px;
  padding: 0 12px;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 100;
}

header.elevated {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  /* box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.05); */
}

header.games {
  background-color: #0b1023;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  color: #fff;
}

header.contact {
  background-color: #000;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  color: #fff;
}

header .logo {
  cursor: pointer;
  height: 30px;
  width: 30px;
}

header .logo svg {
  transition: fill 0.2s;

  fill: currentColor;
}

header > nav {
  display: flex;
  flex-flow: row nowrap;
  flex-grow: 1;
  justify-content: center;
  margin: 0 10px;
}

header > nav a {
  /* transition: color 0.2s; */

  font-weight: 500;
  display: block;
  color: currentColor;
  text-decoration: none;
  white-space: nowrap;
  margin: 0 20px;
}

header > nav a.active {
  color: #007bff;
}
</style>
