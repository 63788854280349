<template>
  <div class="main terms">
    <div lang="en" class="wrapper">
      <div class="privacy-body">
        <h2 class="privacy-title">FAQs</h2>
        <div class="privacy-description privacy-body-item">
          <b>1. How does Supergames protect my privacy? </b><br />
          <p class="indent-one">
            We, Supergames Inc., understand the importance of your privacy. This
            Privacy Policy is designed to inform you on how we use the
            information we collect and how we protect your data. We
            <b>DO NOT STORE</b> any personal information, and we
            <b>DO NOT SELL</b> your data to any third-party company.
          </p>
        </div>
        <div class="privacy-description privacy-body-item">
          <b
            >2. I don’t want to play a game by Supergames anymore. How do I remove
            the game? </b
          ><br />
          <p class="indent-one">
            From your News Feed, click the arrow found at the top right. Click
            <b>Settings</b>.
          </p>
          <p class="indent-one">
            Click <b>Instant Games</b> found in the left menu. Click the box
            next to the game you’d like to remove.
          </p>
          <p class="indent-one">Click <b>Remove</b>.</p>
          <p class="indent-one">
            A pop-up message will appear. Check the box to delete your game
            history. Click <b>Remove</b>.
          </p>
        </div>
      </div>
      <hr />
      <div class="privacy-body">
        <h2 class="privacy-title">Privacy Policy</h2>
        <div class="privacy-description privacy-body-item">
          <b>1. General</b><br />
          <p class="indent-one">
            This Privacy Policy was developed with your (hereinafter referred to
            as the “user”, “users”, or “their”) ease of understanding in mind.
            Supergames Inc. (hereinafter referred to as “Supergames”, or “our”) develops
            and publishes Facebook Instant Games. This Privacy Policy applies
            whenever you use our “Services,” which means our games. This Privacy
            Policy describes the methods of collection and protection of the
            user’s data and other such pertinent information.
          </p>
          <p class="indent-one">
            This Privacy Policy is available for perusal at all times. Should
            there be any changes or amendments to this Privacy Policy, Supergames
            will notify the user by posting the amended version on this site.
          </p>
        </div>
        <div class="privacy-description privacy-body-item">
          <b>2. Information collected while the user plays our Services</b
          ><br />
          <div class="indent-one">
            <b> 2.1 </b>
            To use our Services, the user may log in through their Facebook
            Messenger account. Through this log in process, our Services collect
            the following information:
            <br />
            <p class="indent-two">
              <b> 2.1.1 </b> The user’s game-scoped ID <br />
              <b> 2.1.2 </b> The user’s Facebook Messenger user ID <br />
              <b> 2.1.3 </b> The user’s game data (score, rank, items, etc.)
              <br />
              <b> 2.1.4 </b> The user’s language setting <br />
              <b> 2.1.5 </b> The timestamp of when the user last accessed the
              application <br />
            </p>
          </div>
          <p class="indent-one">
            <b> 2.2 </b> Upon registration, user’s game-scope ID, Facebook
            Messenger user ID, and user’s language setting are collected
            enabling our Services to send push notifications sent through
            Facebook Messenger Game Bots.
          </p>
          <p class="indent-one">
            <b> 2.3 </b> Personal information that identifies the user such as
            name and profile picture are <b>NEVER</b> stored or cached anywhere,
            but only used to create customized user experience such as
            personalized share images.
          </p>
          <p class="indent-one">
            <b> 2.4 </b> Game data associated with the behavior of the user in
            our Services are <b>NEVER</b> stored or cached anywhere, but only
            used to continue user progress.
          </p>
          <p class="indent-one">
            <b> 2.5 </b> In order to manage the information our Services receive
            through Facebook, the user must follow the instructions provided by
            Facebook for modifying said user’s information and privacy settings.
            The privacy management tools for applications on Facebook can be
            found at
            <a href="https://www.facebook.com/settings?tab=instant_games"
              ><span style="font-weight: 400;"
                >https://www.facebook.com/settings?tab=instant_games</span
              ></a
            >
          </p>
        </div>
        <div class="privacy-description privacy-body-item">
          <b>3. Push Notifications</b><br />
          <p class="indent-one">
            <b> 3.1 </b> Users are provided information about our Services and
            pertinent content through messages sent through Facebook Game
            Messenger Bots (hereinafter referred to as “Push Notifications”).
          </p>
          <p class="indent-one">
            <b> 3.2 </b> Push Notifications are sent through Facebook messenger,
            and are set with consent from the user. The user’s browser and/or
            app and/or device must also support such push notifications. In
            order to facilitate the delivery of such Push Notifications, our
            Services must store the user’s game-scoped ID, Facebook messenger
            user ID, user’s language setting, and timestamp of last access which
            are all provided when the user plays the game for the very first
            time.
          </p>
          <p class="indent-one">
            <b> 3.3 </b> The user may choose to opt out of the push notification
            service at any time by disabling the function through the user’s
            Facebook Messenger settings.
          </p>
        </div>
        <div class="privacy-description privacy-body-item">
          <b>4. User’s Right to Revoke Data Access</b><br />
          <p class="indent-one">
            <b> 4.1 </b> Users can always choose to remove our Services from
            their list of active Instant Games which can be found at
            <a href="https://www.facebook.com/settings?tab=instant_games"
              ><span style="font-weight: 400;"
                >https://www.facebook.com/settings?tab=instant_games</span
              ></a
            >
          </p>
          <p class="indent-one">
            <b> 4.2 </b> Upon revoking data access, our Services will not be
            able to make additional requests for user data or send messages.
          </p>
          <p class="indent-one">
            <b> 4.3 </b> Users may also opt to delete their game history on
            Facebook for our Services by checking the box provided in the
            deletion process.
          </p>
        </div>
        <div class="privacy-description privacy-body-item">
          <b>5. Office in Charge of Protection of Information</b><br />
          <p class="indent-one">
            <b> 5.1 </b> To facilitate the protection of information and process
            related complaints, Supergames designates an officer in charge of
            personal information protection.
          </p>
          <p class="indent-one">
            <b> 5.2 </b> Should the user have any concern regarding their
            personal information, they may report it to the aforementioned
            officer. Supergames will review such concerns and provide a response.
          </p>
          <p class="indent-one">
            <b> 5.3 </b> The user is obliged to provide sufficient and accurate
            information so that their concerns may be evaluated properly. Supergames
            may ask the user to provide additional information as a condition
            for the evaluation of their concern.
          </p>
          <p class="indent-one">
            <b> 5.4 </b> The user may use the following contact details:
          </p>
          <p>
            <span style="margin-left: 20px; font-weight: 400;"
              >Name: Supergames</span
            >
          </p>
          <p>
            <span style="margin-left: 20px; font-weight: 400;"
              >E-mail:
              <a href="mailto:contact@supergames.dev">contact@supergames.dev</a></span
            >
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  metaInfo: {
    title: "Privacy Policy – Supergames"
  }
};
</script>

<style scoped>
.terms {
  padding: 60px;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 12px 0;
}

.privacy-description {
  margin: 25px 0;
}

hr {
  margin: 1.5em 0;
}

a {
  color: #007bff;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

@media only screen and (max-width: 650px) {
  .terms {
    padding: 60px 15px 15px;
  }
}
</style>
